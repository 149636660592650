
import { OptionsClass } from "@/domain/entities/MainApp";
import { Options, prop, Vue } from "vue-class-component";

class Props {
  modelValue = prop<any>({
    required: true
  });
  placeholder = prop<string>({
    default: "",
    type: String
  });
  value = prop<string>({
    default: "",
    type: String
  });
  isDisabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  disableBackground = prop<boolean>({
    default: false,
    type: Boolean
  });
  isExpand = prop<string | boolean>({
    default: "",
    type: Boolean || String
  });
  options = prop<Array<any>>({
    default: [],
    type: Array
  });
  isCustomWidth = prop<boolean>({
    default: false,
    type: Boolean
  });
  selectedItem = prop<any>({
    default: { name: "", value: "" } || ""
  });
  customValueStyle = prop<string>({
    default: "",
    type: String
  });
  customChevronStyle = prop<string>({
    default: "",
    type: String
  });
  customOptionStyle = prop<string>({
    default: "",
    type: String
  });
  isCustomMaxHeight = prop<boolean>({
    default: false,
    type: Boolean
  });
  isCheck = prop<boolean>({
    default: false,
    type: Boolean
  });
  counter = prop<number>({
    default: 0,
    type: Number
  });
  error = prop<boolean>({
    default: false,
    type: Boolean
  });
  errorCaption = prop<string>({
    default: "",
    type: String
  });
  expandUpwards = prop<boolean>({
    default: false
  });
  customClassExpand = prop<string>({
    default: "absolute flex",
    type: String
  });
  customClassHeight = prop<string>({
    default: "h-10",
    type: String
  });
  preventUnselect = prop<boolean>({
    default: false,
    type: Boolean
  });
}

@Options({
  emits: [
    "update:isExpand",
    "update:modelValue",
    "update:selectedItem",
    "select"
  ]
})
export default class CustomSelect extends Vue.with(Props) {
  openSelect = false;
  expandSelect() {
    if (!this.isDisabled) {
      this.openSelect = true;
      this.$emit("update:isExpand", true);
    }
  }
  closeSelect() {
    if (!this.isDisabled) {
      this.openSelect = false;
      this.$emit("update:isExpand", false);
    }
  }

  get placeholderStyle() {
    if (this.isDisabled) {
      return "text-gray-lp-600";
    }
    if (this.value || this.selectedItem?.value || this.defaultValue) {
      return "text-black-lp-300";
    }
    return "text-gray-lp-600";
  }

  get defaultValue() {
    if (typeof this.selectedItem === "string") {
      return this.options.find(
        item =>
          item.name === this.selectedItem || item.value === this.selectedItem
      )?.name;
    }
    return "";
  }

  isActive(option: any) {
    return this.selectedItem.value === option.value;
  }

  onSelect(option: any) {
    if (this.isActive(option) && !this.preventUnselect) {
      this.$emit("update:modelValue", { name: "", value: "" });
      this.$emit("update:selectedItem", { name: "", value: "" });
      this.$emit("select", new OptionsClass());
    } else {
      this.$emit("update:modelValue", option);
      this.$emit("update:selectedItem", option);
      this.$emit("select", option);
    }
    this.closeSelect();
  }
}
